import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../../services/AuthContextModule';

export default function SuperAdminAllUsers() {

  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const userId = authState.user;

  const [allUsers,setAllUsers] = useState([]);
  const [usersTable, setUsersTable] = useState([]);
  // more could be added here

  const [searchValue,setSearchValue] = useState("");

  const [usersTabs, setUsersTabs] = useState(
    [
      {role:"students",active:false, user:[]},
      {role:"staff",active:false, user:[]},
    ]
  )

  // when clicked 
  const handleSetUsersTabs = (index,role)=>{
    const refreshedData = [...usersTabs];
    refreshedData.forEach((tab)=>{
      tab.active = false;

      tab.user = allUsers.filter((user)=>{

        if(typeof user.role === "string"){
          if(tab.role === "students"){
            return user.role === "student"
          }
        } else if(Array.isArray(user.role)){
          if(tab.role === "staff"){
            return user.role.includes("counselors") || user.role.includes("schoolAdmin") || user.role.includes("teacher")
          }
        }

        // can keep adding here
      });
    });
    refreshedData[index].active = true;
    setUsersTabs(refreshedData);
    handleSetUsersTable(refreshedData[index].user, role)
  }
  
  const handleSetUsersTable = (userData)=>{
    setUsersTable(userData)
  }

  const initiateTabsData = (userData)=>{
    const refreshedData = [...usersTabs];

    refreshedData.forEach((tab)=>{
      tab.active = false;

      tab.user = userData.filter((user)=>{

        if(typeof user.role === "string"){
          if(tab.role === "students"){
            return user.role === "student"
          }
        }else if(Array.isArray(user.role)){
          if(tab.role === "staff"){
            return user.role.includes("counselors") || user.role.includes("schoolAdmin") || user.role.includes("teacher")
          }
        }
        // can keep adding here
      });
    });
    setUsersTabs(refreshedData);
  }

  const getAllUsers = async ()=>{
    let allUsersCombined = [];    // combine students and staff together

    await useAxiosWithInterceptor.get("/api/super-admin/teachers",{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          allUsersCombined = [...allUsersCombined,...response.data.payload];
        }
      })
      .catch((error)=>{
        console.log(error)
      })
      
    await useAxiosWithInterceptor.get("/api/super-admin/students",{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          allUsersCombined = [...allUsersCombined,...response.data.payload];
        setUsersTable(response.data.payload);
        }
      })
      .catch((error)=>{
        console.log(error)
      })

      setAllUsers(allUsersCombined);
      initiateTabsData(allUsersCombined);
      console.log(usersTabs)
  }

  useEffect(()=>{
    getAllUsers();
  },[])

  return (
    <>
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
          <div className='overflow-x-auto shadow-sm '>
            <div className="grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
            {usersTabs.map((tab,index)=>{
                return (
                  <button 
                    key={index}
                    className={`flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-3 sm:px-6 lg:border-t-0 xl:px-8 hover:cursor-pointer ${tab.active?"bg-green-500 text-white":"bg-white"}`}
                    onClick={()=>{handleSetUsersTabs(index,tab.role)}}>
                      <p className={`w-full text-sm font-medium leading-6 text-gray-500 ${tab.active?"text-white":"text-gray-500"}`}>{tab.role}</p>
                      <p className="w-full flex-none text-3xl font-medium leading-10 tracking-tight ">
                        {tab.user.length}
                      </p>
                  </button>
                )})
            }
            </div>
            </div>
            <div className='my-1 py-1 flex align-middle'>
              <div className='flex items-center w-full lg:w-4/5 md:w-4/5 sm:w-4/5'>
                <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search User</p>
              <input 
                // value={searchValue}
                // onChange={handleSearch}
                placeholder='find user' 
                className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
              </div>
          </div>

          <div className="flow-root hover:shadow hover:rounded-2xl">
            <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-8 ">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl ">
                  <table className='min-w-full divide-y divide-gray-700 rounded-2xl' >
                  <thead className='bg-green-500 text-white'>
                    <tr className='text-center'>
                      <th scope="col"  className="py-2 px-3 font-semibold sm:pl-6 w-48">Full Name</th>
                      <th scope="col" className="px-3 py-2 font-semibold w-48">Role</th>
                      <th scope="col"  className="px-3 py-2 font-semibold w-44">School</th>
                      <th scope="col" className="px-3 py-2 font-semibold w-44">Verified</th>
                    </tr>
                  </thead>

                  <tbody className='divide-y divide-gray-300'>
                    {usersTable.map((user)=>{
                      return (
                        <tr key={user.id} className='hover:bg-gray-100 bg-white'>
                          <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm text-blue-600 font-semibold '>
                            <div className="flex items-center">
                              <div className="h-8 w-8 flex-shrink-0">
                                {
                                  user.profilePicture? <img className="h-8 w-8 rounded-full" src={user.profilePicture} alt="" /> :
                                  <span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500">
                                    <span className="text-sm font-medium leading-none text-white">{user.firstName[0]}{user.lastName[0]}</span>
                                  </span>
                                }
                              </div>
                              <div className="ml-4">
                              <Link to={`/admin/users/${user.id}`}>
                                <div className="font-medium text-gray-900">{user.firstName} {user.lastName}</div>
                              </Link>
                                <div className="mt-1 text-gray-500">{user.email}</div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 text-sm text-center font-semibold '>
                            <p className='py-1'>{displayUserRole(user.role)}</p>
                          </td>
                          <td className='text-sm whitespace-nowrap px-3 text-center'>{user.school?.schoolName}</td>
                          <td className={`whitespace-nowrap px-3 text-center px-2 py-1 rounded font-semibold ${user.activated?"text-green-700":"text-red-500"}`}>{user?.activated?"Yes":"No"}</td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
              </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </Layout>
</>
  )
}



const displayUserRole = (roles)=>{

  if(roles.includes("teacher")&&roles.includes("counselor")&&roles.includes("administrator")){
    return (
      <>
        Teacher / Counselor / Administrator
      </>
    )
  } else if(roles.includes("administrator")&&roles.includes("teacher")){
    return (
      <>
        Administrator / Teacher
      </>
    )
  } else if(roles.includes("administrator")&&roles.includes("counselor")){
    return (
      <>
        Administrator / Counselor
      </>
    )
  } else if(roles.includes("teacher")&&roles.includes("counselor")){
    return (
      <>
        Teacher / Counselor
      </>
    )
  } else {
    return (
      <>
        {roles}
      </>
    )
  }
}