import React, { useContext, useState,useEffect,useRef } from 'react'
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';
import { NewEventFormContext, NewEventFormDispatchContext } from '../providers/EventForm_Context';
import { EventFormRequiredFieldsContext, EventFormRequiredFieldsDispatchContext } from '../providers/EventFormValidation_Context';
import { XCircleIcon ,CheckCircleIcon} from '@heroicons/react/24/solid'

export default function EventStudentsInviter({attributeName,inputName}){

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
    // contexts
  const eventFormState = useContext(NewEventFormContext);
  const dispatch = useContext(NewEventFormDispatchContext);
  const requiredFields = useContext(EventFormRequiredFieldsContext);
  const formCheckDispatch = useContext(EventFormRequiredFieldsDispatchContext);

  const [gradeLevels, setGradeLevels] = useState([]);
  const [allStudents, setAllStudents] = useState([]);

  // set up for individually selection students
  const [searchText, setSearchText] = useState("");
  const [listHidden, setListHidden] = useState(true);
  const [filteredList, setFilteredList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

const handleKeydown = (e) => {
  const { key } = e;
  let newIndex;

  if (key === "ArrowUp") {
    e.preventDefault();
    newIndex = (selectedIndex - 1 + filteredList.length) % filteredList.length;
    setSelectedIndex(newIndex);
  } else if (key === "ArrowDown") {
    e.preventDefault();
    newIndex = (selectedIndex + 1) % filteredList.length;
    setSelectedIndex(newIndex);
  } else if (key === "Enter") {
    e.preventDefault();
    if (selectedIndex >= 0 && selectedIndex < filteredList.length) {
      selectAStudent(filteredList[selectedIndex]);
    }
  } else if(key === "Escape"){
    e.preventDefault();
    setListHidden(true)
    setSearchText("")
  }
};

const selectAStudent = (newStudent)=>{
  // const tempList = [...eventFormState.selectedStudents];
  const ifAlreadyInvited = eventFormState.selectedStudents.some((student)=> student.id === newStudent.id);
  // check if student already exist in the list.
  if(!ifAlreadyInvited){
    dispatch({
      type:"inviteStudents",
      name:attributeName,
      content:[newStudent]
    })
    setSearchText("");
    setListHidden(true);
  }else{
    // setSearchText("");
    setListHidden(true);
  }
}

const checkIfInvited = (newStudent)=>{
  return eventFormState.selectedStudents.some((student)=> student.id === newStudent.id);
}

  const handleSearchText = (e)=>{
    const text = e.target.value;
    setSearchText(e.target.value);
    console.log(text)
    const tempList = allStudents.filter((student) =>
      student?.firstName.toLowerCase().includes(text.toLowerCase())
    )
    setListHidden(text === '');
    setFilteredList(tempList)
  }

  const getAllStudents = async() =>{
      await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/students`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            // console.log(response.data.payload)
            setAllStudents(response.data.payload)
            let allGrades = response.data.payload.map((student)=>{
              return student.gradeLevel
          }).sort((a,b)=>a-b);
            setGradeLevels([... new Set(allGrades)])
          }
        })
        .catch((error)=>{
          console.log(error)
        })
    }

  const handleChecked = (e)=>{
      const entireGrade = allStudents.filter((student)=>{
          return student.gradeLevel === + e.target.value;
      });
      if(e.target.checked){
        dispatch({
          type:"inviteStudents",
          name:attributeName,
          content:entireGrade
        })
      }else{
        dispatch({
          type:"unInviteStudents",
          name:attributeName,
          content:entireGrade
        })
      }
  }

  const removeOneStudent = (student)=>{
    dispatch({
      type:"unInviteStudents",
      name:attributeName,
      content:[student]
    })
  }
  
  const checkedOrNot = (gradeLevel)=>{
    const selectedStudents = eventFormState.selectedStudents;
    return selectedStudents.some(student=>student.gradeLevel === gradeLevel)
  }

  const gradeCount = (gradeLevel)=>{
    let count = 0;
    allStudents.forEach((student)=>{
      if(student.gradeLevel === gradeLevel){
        count ++
      }
    })
    return count;
  }

  useEffect(()=>{
    getAllStudents();
},[])

  return (
    <>
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
        <label htmlFor= {attributeName} className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
            {inputName}
            <p className='text-blue-600'>Total: {eventFormState.selectedStudents.length}</p>
        </label>
        <form className='my-1 col-span-2'>
          <div className='flex flex-wrap text-xs font-semibold '>
          {
            gradeLevels.map((grade,index)=>{
              return (
                  grade && 
                    <div key={index} className='flex space-x-1 py-2 mx-2'>
                      <input 
                        type='checkbox' 
                        onChange={handleChecked} 
                        
                        value={grade} 
                        checked={checkedOrNot(grade)}
          // if Context contains this grade value? checked
                        label={`Grade ${grade}`}
                        className="h-5 w-5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                      <label className='text-sm'> Grade {grade} 
                        <span className='text-blue-500'>
                        ({gradeCount(grade)})
                        </span>
                      </label>
                    </div>
                  )
            })
          }
          </div>
          <div 
            onKeyDown={handleKeydown}
            className='relative flex items-center py-2 gap-x-4 ' >
            <input 
                ref={inputRef}
                className="text-sm w-2/3 rounded border-0 py-2 px-2 bg-slate-50"
                value={searchText}
                onChange={handleSearchText}
                placeholder='Student Name'
             />
             <ul 
                hidden = {listHidden} className="absolute top-full bg-white border-2 rounded lg:w-1/2 h-48 overflow-y-auto z-10">
                  {
                    filteredList.length > 0? filteredList.map((student,index)=>{
                      const itemClasses = index === selectedIndex?'bg-slate-200':'';
                      return (
                        <li
                          key={student.id} 
                          // className={`list-group-item-hover list-group-item-action`}
                          className={`p-2 text-left cursor-pointer border-b hover:bg-sky-200 text-xs font-semibold ${itemClasses}`}
                          onClick={()=>selectAStudent(student)}
                          >
                          <div className='flex justify-between py-1'>
                            <p className='font-bold text-sm text-sky-600'>
                            {student?.firstName} {student?.lastName}
                            </p>
                            {checkIfInvited(student)? <div className='h-5 w-5 text-green-500'>
                            <CheckCircleIcon />
                            </div>:null}
                          </div>
                        </li>
                      )} ): <li className='p-2 text-left cursor-pointer border-b hover:bg-teal-200 text-xs font-semibold'>No student found</li>
                  }
              </ul>
          </div>
        </form>
      </div>
      <div className='pb-3 pt-1'>
      {/* add a progress bar, indication % of students of the grade? or 4/15 */}
        {
          eventFormState[attributeName].map((student)=>{
            return (
              <div key={student.id} className='mx-1 my-1 px-2 py-1 rounded inline-flex bg-sky-600 shadow-sm text-white border-1' >
                <div className='flex px-2 content-center items-center space-x-2'>
                  <p className='text-sm font-semibold ' >{student.firstName} {student.lastName}</p>
                  <p className='text-xs font-semibold'>({student.gradeLevel})</p>
                  <XCircleIcon className='w-6 h-6 hover:cursor-pointer' onClick={()=>{removeOneStudent(student)}}/>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
    </>
  )
}
