import React from 'react'
import Select from 'react-select';
import {getNames } from 'country-list';

export default function StudentCountryPicker({handleSelect,originalCountry}) {
    const formattedOptions = getNames().map((country)=>{
        return {value:country,label:country}
    })
    const initialCountry = formattedOptions.find(option => option.value === originalCountry);
    
    return (
      <Select
          closeMenuOnSelect={true}
          options= {formattedOptions}
          onChange={handleSelect}
          defaultInputValue={initialCountry?.label}
      />
    )
}
