import React, { useState,useEffect } from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';

import Layout from '../../../components/Layout';
import { displayRoles } from '../../../../utils/displayRoles';

export default function SuperAdminAllSchoolAdmin() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const [allCounselors,setAllCounselors] = useState([]);

  const getAllSchoolAdmins = async()=>{
      await useAxiosWithInterceptor.get("/api/super-admin/school-admins",{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          setAllCounselors(response.data.payload)
        }
      })
  }

  useEffect(()=>{
    getAllSchoolAdmins();
  },[]);
  
  return (
    <>
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
          <p className='text-3xl font-bold py-1 px-2'>Administrators of Schools</p>
          <div className='my-2 flex align-middle'>
            <div className='flex items-center w-full lg:w-4/5 md:w-4/5 sm:w-4/5'>
              <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Counselor</p>
              <input 
              // value={searchValue}
              // onChange={handleSearch}
                placeholder='find counselors' 
                className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
            </div>
          </div>
          <div className="flow-root hover:shadow hover:rounded-2xl">
            <div className="-my-2 overflow-x-auto sm:-mx-8 lg:-mx-8 ">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl ">
                    <table className='min-w-full divide-y divide-gray-700 rounded-2xl' >
                      <thead className='bg-green-500 text-white'>
                        <tr className='text-center text-sm'>
                          <th scope="col"  className="px-3 py-2 font-semibold sm:pl-6 w-48">Name</th>
                          <th scope="col" className="px-3 py-2 font-semibold w-48">Email</th>
                          <th scope="col"  className="px-3 py-2 font-semibold w-44">School</th>
                          <th scope="col" className="px-3 py-2 font-semibold w-44">Role</th>
                          
                        </tr>
                      </thead>
                      <tbody className='divide-y divide-gray-300'>
                      {allCounselors.map((counselor)=>{
                          return (
                            <tr key={counselor.id} className='bg-white hover:bg-gray-300 text-center font-semibold ' >
                              <td className='whitespace-nowrap py-2 pl-4 pr-3 text-xs text-blue-600 font-semibold '>{counselor.firstName} {counselor.lastName}</td>
                              <td className='whitespace-nowrap px-3 text-xs'>{counselor.email}</td>
                              <td className='text-xs whitespace-nowrap px-3'>
                                {counselor.school?.schoolName}
                              </td>
                              <td className='text-xs whitespace-nowrap px-3'>
                                {displayRoles(counselor?.assignments?.map((role)=>role?.role?.roleName))}
                              </td>
                            
                            </tr>
                          )})
                        }
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
        </Layout>
    </>
  )
}
