import React,{useState,useEffect,useContext}  from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { useBanner } from '../../../../services/SystemBannerMessage';

export default function RoleOptionBar({schoolId,staffId,staffCurrentRole,getAllTeachers}) {
    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const {showBanner} = useBanner();
    const currentUserRoles = authState.role;

    const [allRoles,setAllRoles] = useState([]);
    
    const [roleOptions,setRoleOptions] = useState([]);

      // need to somehow get the role_id passed down, not e.target.value
    const handleRoleSelection = (roleOption)=>{
        const refreshedOptions = [...roleOptions];
        const selectedCount = refreshedOptions.filter((option)=>option.selected === true).length;

        const currentStatus = refreshedOptions.find((option)=>option.value === roleOption.value).selected === true;

        if(roleOption.label === "administrator"){
            showBanner("Please contact Schoolley Support to modify Administrator role", "bg-yellow-400")
            return 
        }

        if(currentStatus){
            if(selectedCount > 1){
                refreshedOptions.find((option)=>option.value === roleOption.value).selected = false;
            }
        } else {
            refreshedOptions.find((option)=>option.value === roleOption.value).selected = true;
        }
        assignRole(roleOption.value);
        setRoleOptions(refreshedOptions);
    }

    const assignRole = async(roleId)=>{

        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/teachers/${staffId}/assign`,{role:roleId},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                getAllTeachers();
                showBanner("Role modified successfully","bg-green-400")
            }
        })
        .catch((error)=>{
            console.log(error);
        })
    }

    const getAllRoles = async()=>{
        await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/all-roles`,{withCredentials:true})
        .then((response)=>{
          if(response.status === 200){
            setAllRoles(response.data.payload)
            const filterRoles = response.data.payload.filter((role)=>{
                return role.roleName !== "student"
            });
            const setUpRoles = filterRoles.map((role,index)=>{
                return {
                    id:role.id,
                    value:role.id,
                    label:role.roleName,
                    bgColor:"bg-sky-500 text-white",selected:false
                }
            })

            const currentRoleSet = new Set(staffCurrentRole.map((role)=>role.role_id)); 
            setUpRoles.forEach((role)=>{
                if(currentRoleSet.has(role.id)){
                    role.selected = true;
                }
            })
            setRoleOptions(setUpRoles);
          }
        })
        .catch((e)=>{
          console.log(e);
        })
      }

    useEffect(()=>{
       getAllRoles();
    },[])

  return (
    <>
        <div>
        {roleOptions.map((option,index)=>{
            return (
                <button key={option.id} onClick={()=>handleRoleSelection(option)} value={option.value} className={`transition ease delay-100 relative inline-flex items-center ${index === 0?"rounded-l-md":index === roleOptions.length-1?"rounded-r-md":null} px-3 py-1 my-0 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-sky-300 focus:z-10 ${option.selected?option.bgColor:""}`}>{option.label}</button>
                )
            })
        }
    </div>
    </>
  )
}
