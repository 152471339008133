import React, { useEffect, useState } from 'react'
import Layout from '../../../components/Layout';
// import { useNavigate } from 'react-router-dom';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
// import { AuthenticationContext } from '../../../../services/AuthContextModule';
// import SuperAdmin_CreateNewUniversity from '../../adminComponents/SuperAdmin_CreateNewUniversity';
import { PhotoIcon } from '@heroicons/react/24/solid';
import { useBanner } from '../../../../services/SystemBannerMessage';
import Papa from 'papaparse';


export default function SuperAdminBulkUniversities() {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    // const {authState} = useContext(AuthenticationContext);
    // const userId = authState.user;
    // const [userInfo,setUserInfo] = useState();
    // const navigate = useNavigate();

    // const [universities, setUniversities] = useState([]);
    // const [uploadComplete, setUploadComplete] = useState(false);
    const [csvData, setCsvData] = useState([]);
    const [existingUniversities, setExistingUniversities] = useState([]);

    const [fileName, setFileName] = useState('Upload a CSV file');
    const {showBanner} = useBanner();

    const handleFileChange = (e)=>{
        const file = e.target.files[0];

        if(file){
            const validFileTypes = ['text/csv', 'application/vnd.ms-excel']; // Add valid MIME types for CSV files

            const fileExtension = file.name.split('.').pop().toLowerCase();
            if (!validFileTypes.includes(file.type) || fileExtension !== 'csv') {
                showBanner("Please upload a valid CSV file.","bg-yellow-500");
                return;
            }
        
            const reader = new FileReader();
            reader.onload = (e)=>{
                const text = e.target.result;
                Papa.parse(text, { 
                  header: true,
                  complete: (result)=>{
                    let cleanStudentData = [];
                    let universityData = result.data;
                    for(let i = 0; i < universityData.length; i++){
                      if(!universityData[i].schoolName|| !universityData[i].schoolName|| !universityData[i].city|| !universityData[i].state || !universityData[i].country || !universityData[i].website){
                        continue;
                      }
                      universityData[i].completed = false;
                      universityData[i].exist = false;
                      universityData[i]['uploaded'] = false;
        
                      for(let j = 0; j < existingUniversities.length; j++){
                        if(universityData[i].website === existingUniversities[j].website || universityData[i].schoolName === existingUniversities[j].schoolName){
                          universityData[i]['exist'] = true;
                        }
                      }
                      cleanStudentData.push(universityData[i]);
                    }
                    setCsvData(cleanStudentData);
                  },
                });
              }
              reader.readAsText(file);      
              setFileName(file.name);  
        }
    }

    const getExistingUniversities = async()=>{
        await useAxiosWithInterceptor.get("/api/super-admin/universities",{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                console.log(response.data.payload)
                setExistingUniversities(response.data.payload)
            }
        }).catch((error)=>{
            console.log(error)
        })
    }

    useEffect(()=>{
        getExistingUniversities();
        document.title = "Import Universities"

    }
    ,[])

  return (
    <Layout>
    <div className="md:w-full w-full flex justify-center">
        <div className="bg-opacity-75 px-1 pb-2 justify-center w-full">
            <div id="event-page-layout" className="w-full h-fit">
                <div className='bg-white rounded-lg px-3 py-2 mb-4 space-y-2' >
                    <p className='font-semibold text-xl'>Instruction:</p>
                    <div className='pl-3 space-y-2 '>
                    <p>Step 1: Download this <a href='/downloads/university_onboard.csv' className='font-semibold text-blue-500' download>CSV File</a></p>
                    <p>Step 2: Fill in universities information</p>
                    <p>Step 3: Upload the completed csv file</p>
                    <p>Step 4: Click Upload</p>
                    </div>
                </div>
                <form className="space-y-8 divide-y divide-gray-200">
                    <div className="mt-2 flex justify-center rounded-lg  px-6 py-10 bg-white ">
                        <div className="text-center ">
                            <PhotoIcon className="mx-auto h-12 w-12 text-gray-300" aria-hidden="true" />
                            <div className="mt-4 flex text-sm leading-6 text-gray-600 ">
                                <label
                                htmlFor="file-upload"
                                className="relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                >
                                <span>{fileName}</span>
                                <input 
                                    id="file-upload" 
                                    name="file-upload" 
                                    type="file" accept='.csv' className="sr-only" 
                                    onChange={handleFileChange} />
                                </label>
                            </div>
                            <p className="text-xs leading-5 text-gray-600">CSV File</p>
                        </div>
                    </div>
                </form>
            </div>
            <div className='px-3 '>
                <p className='font-semibold text-sm text-indigo-500'>count: {csvData.length} schools</p>
            </div>
            <div id='new-universities'>
                <div className="rounded-lg shadow-sm border-1 my-2 overflow-x-auto bg-white">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                       <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                            <th scope="col" className="py-1.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                School
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                city
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                state
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                website
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                country
                            </th>
                            <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                remove
                            </th>
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200">
                        {
                            csvData.map((school,index)=>{
                                return(
                                    <tr key={index} className="bg-white">
                                        <td className="px-3 py-2 whitespace-nowrap">
                                            <div className="text-sm font-semibold text-gray-900">{school.schoolName}</div>
                                        </td>
                                        <td className="px-3 py-2 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{school.city}</div>
                                        </td>
                                        <td className="px-3 py-2 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{school.state}</div>
                                        </td>
                                        <td className="px-3 py-2 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{school.website}</div>
                                        </td>
                                        <td className="px-3 py-2 whitespace-nowrap">
                                            <div className="text-sm text-gray-900">{school.country}</div>
                                        </td>
                                        <td className="px-3 py-2 whitespace-nowrap">
                                            <div className="text-sm text-gray-900 hover:cursor-pointer text-red-500">remove</div>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                       </table>

                    </div>
                </div>
            </div>
        </div>
    </div>
    </Layout>
  )
}
