import React, { useEffect, useState } from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor'
import { useNavigate,useLocation, Link } from 'react-router-dom';
import useAuthenticationContext from "../../../../hooks/authStateHook";
import Layout from '../../../components/Layout';

export default function SuperAdminAllSchools() {
  const [schools, setSchools] = useState([]);
  const useAxiosWithInterceptor = axiosWithInterceptor();

  const getSchools = async ()=>{
    await useAxiosWithInterceptor.get("/api/super-admin/schools",{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setSchools(response.data.payload);
        console.log(response.data.payload)
      }
    }).catch((error)=>{
      console.log(error)
      // navigateTo(-1)
      // navigateTo('',{state:{from: location},replace:true})
    })
  }

  useEffect(()=>{
    getSchools()
  },[])

  return (
    <>
    <Layout>
      <div className='md:w-full w-full'>          
        <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
        <p className='text-3xl font-bold py-1 px-2'>Schools</p>

        <div className='my-1 py-1 lg:flex '>
          <div className='w-full'>
            <div className='flex items-center w-full'>
              <p className='py-2 px-2 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search School</p>
              <input 
                // value={searchValue}
                // onChange={handleSearch}
                placeholder='find School' 
                className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
            </div>
          </div>
          <div className='w-full lg:w-1/4 flex justify-center '>
            <Link to={`/admin/schools/new`} className='w-full'>
              <button className='w-full py-2 px-5 bg-sky-600 rounded-xl text-white shadow-sm text-sm font-semibold'>
                New School
              </button>
            </Link>
          </div>
        </div>

        <div className="flow-root hover:shadow hover:rounded-2xl">
          <div className=" overflow-x-auto  ">
            <div className="inline-block min-w-full py-0 align-middle ">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl ">
                  <table className='min-w-full divide-y divide-gray-700 rounded-2xl' >
                    <thead className='bg-green-500 text-white'>
                      <tr className='text-center text-sm'>
                        <th scope="col"  className="py-2 px-3 font-semibold sm:pl-6 w-48">School Name</th>
                        <th scope="col" className="px-3 py-2 font-semibold w-48">City</th>
                        <th scope="col"  className="px-3 py-2 font-semibold w-44">Country</th>
                        <th scope="col"  className="px-3 py-2 font-semibold w-44">Staff</th>
                        <th scope="col" className="px-3 py-2 font-semibold w-44">Students</th>
                        <th scope="col" className="px-3 py-2 font-semibold w-44">Events</th>
                      </tr>
                    </thead>

                    <tbody className='divide-y divide-gray-300'>
                      {
                        schools.map((school)=>{
                          return (
                            <tr key={school.id} className='hover:bg-gray-100 text-center font-semibold bg-white' >
                              <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm text-blue-600  '>
                              <Link to={`/admin/schools/${school.id}/details`}  >
                              {school?.schoolName}
                              </Link>
                              </td>
                              <td className='whitespace-nowrap px-3 text-sm'>{school?.city}</td>
                              <td className='whitespace-nowrap px-3 text-sm'>
                              {school?.country}
                              </td>
                              <td className='text-sm whitespace-nowrap px-3'>
                              {school?.staffCount}
                              </td>
                              <td className='text-sm whitespace-nowrap px-3'>
                              {school?.studentCount}
                              </td>
                              <td className='text-sm whitespace-nowrap px-3'>
                              {school?.eventCount}
                              </td>
                            </tr>
                          )})
                      }
                    </tbody>
                  </table>
                </div>
            </div>
          </div>
        </div>

        </div>
      </div>
    </Layout>
  </>
  )
}
