import React from 'react';

import { NewEventFormContext } from '../providers/EventForm_Context';
import EventUniversitySearch from '../components/EventForm_UniversitySearch';
import EventStudentsInviter from '../components/EventForm_StudentInviter';
import EventUniversitySelector from '../components/EventForm_UniversitySelector';
import EventParentInviter from '../components/EventForm_ParentInviter';

export default function EventAttendance() {
    
  return (
    <>
      <div className='flex justify-center'>
      </div>
      <div className="space-y-8 border-b border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0 shadow-sm rounded-2xl px-3">
        <EventUniversitySelector attributeName={"selectedUniversities"} inputName={"Select Universities"} />
        <EventStudentsInviter attributeName={"selectedStudents"} inputName={"Invite Students"} />
        <EventParentInviter attributeName={"selectedParents"} inputName={"Invite Parents"} />
        
        {/* a progress % bar to indicate full selection or partial, or a (14/15) indication */}
      </div>
    </>
  )
}
