import React from 'react'
import Layout from '../../../components/Layout'



export default function SuperAdminTeacherProfile() {
  return (
    <Layout>
        <div>AdminTeacherProfile</div>
    </Layout>
  )
}
