import React,{useEffect,useState,useContext} from 'react';
import Layout from '../../../components/Layout';
import {useParams } from 'react-router-dom';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import ProfileAvatar from '../../../components/Avatar';
import ArchiveStaffButton from '../administratorComponents/ArchiveStaffButton';
import UserEditPhoto from '../../../components/UserEditPhoto';
import { useNavigate,Link } from 'react-router-dom';
import { useBanner } from '../../../../services/SystemBannerMessage';
import moment from 'moment';
import { PencilIcon } from '@heroicons/react/24/outline';
export default function SchoolAdminStaffProfile() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {showBanner} = useBanner();

  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const {staffId} = useParams();
  const navigateTo = useNavigate();

  const [events, setEvents] = useState([]);
  const [staffInfo, setStaffInfo] = useState({});

  const getTeacherProfile = async()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/teachers/${staffId}`,{withCredentials:true})
    .then((response)=>{
        if(response.status === 200){
          setStaffInfo(response.data.payload)
        }
    })
    .catch((error)=>{
        console.log(error);
        showBanner("Staff Not found")
        navigateTo('/school/administrator/staff',{replace:true})
    })
  }

  useEffect(()=>{
    getTeacherProfile();
    document.title = "Staff Profile";
  },[]);

  return (
    <>
    <Layout>
      <div className='w-full h-full'>
        <div className="bg-opacity-75 rounded-2xl p-2 lg:mx-10">
          <div className='flex space-x-10 w-full px-3 py-2 rounded-2xl  bg-indigo-500'>
            <div id='student-basic' className=''>
              <div className='flex justify-center'>
                <ProfileAvatar user={staffInfo} size={20}/>
              </div>
              <div className='text-center'>
                <UserEditPhoto refreshUser={getTeacherProfile} />
              </div>
            </div>
            <div className=''>
              <div className='my-2'>
                <p className='font-bold text-3xl text-white'>{staffInfo?.firstName} {staffInfo?.lastName}</p>
              </div>
            </div>
          </div>
                        {/* basic info */}
          <div id='student-profile' className='rounded-lg shadow-sm my-2 space-y-2 bg-white'>
            <div className='text-xl py-2 px-3 rounded-t-md bg-slate-200 font-semibold flex justify-between'>
              <p >Basic Information</p>
              {
                authState.role.includes("administrator")?              <button>
                  <PencilIcon className='w-6 h-6' />
                </button>
                :null
              } 
            </div>

            <div className='lg:grid grid-cols-2 px-2'>
              <div id='column-one' className='col-span-1'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Email:</p>
                  <p className='font-semibold text-blue-500'>{staffInfo?.email}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>School: </p>
                  <p className='font-semibold text-blue-500'>{staffInfo?.school?.schoolName}</p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Role: </p>
                  <p className='font-semibold text-blue-500'>{displayRoles(staffInfo?.assignments)}
                  </p>
                </div>
              </div>

              <div id='column-two' className='col-span-1'>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Date of Birth:</p>
                  <p className='font-semibold text-blue-500'>{
                    staffInfo?.dateOfBirth && moment(staffInfo?.dateOfBirth).format('YYYY-MM-DD')
                    }</p>
                </div>

                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Nationality:</p>
                  <p className='font-semibold text-blue-500'></p>
                </div>
                <div className='flex space-x-5 items-center px-3 py-2 '>
                  <p className='text-sm'>Member Since:</p>
                  <p className='font-semibold text-blue-500 text-sm'>
                    {staffInfo?.createdAt && moment(staffInfo?.createdAt).format('YYYY-MM-DD')}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className='px-2 py-1 my-2 flex space-x-4'>
            <ArchiveStaffButton staff={staffInfo}/>
          </div>
          <p className='text-xl py-1 px-3 rounded-t-md font-semibold flex justify-between'>Activities</p>
          <div className=" flow-root">
            <div className=" overflow-x-auto ">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-6">
                        Event
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Date
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                        Attendance
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-sm font-semibold text-gray-900">
                        Event Type
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                    {events.map((event) => (
                      <tr key={event?.id}>
                        <td className="whitespace-nowrap py-2 pl-4 pr-3 text-sm font-bold text-blue-500 text-center sm:pl-6">
                           <Link to={`/school/events/${event?.id}`}>
                            {event?.eventName}
                           </Link>
                        </td>
                        <td className="whitespace-nowrap px-3 py-2 text-sm t font-semibold  text-center">
                          {moment(event?.eventDateTime).calendar("DDDD")}
                        </td>
                        <td className={`whitespace-nowrap px-3 py-2 font-semibold text-sm text-center`}>
                            {event?.invitations?.filter((invitation)=>{
                                return invitation?.status === "accepted"
                                }
                            ).length}
                        </td>
                        <td className="whitespace-nowrap px-3 text-sm text-gray-500 font-semibold ">
                            <p className='rounded-2xl bg-blue-500 text-white w-fit px-2 '>
                            {event?.eventCategory?.categoryName}
                            </p>
                        </td>
                      </tr>
                    ))}
                    </tbody>
                </table>
                </div>
              </div>
            </div>
          </div>
            {/* user information section */}
      </div>
      </div>
    </Layout>
    </>
  )
}

const displayRoles = (assignments)=>{
  // const roleOrder = ['schoolAdmin', 'teacher', 'counselor','administrator']; // Define the preferred order of roles

  // const sortedRoles = assignments.filter(assign => roleOrder.includes(assign?.role?.roleName)) // Filter only known roles
  // .sort((a, b) => roleOrder.indexOf(a) - roleOrder.indexOf(b)); // Sort roles based on the preferred order

  //   return <>{sortedRoles.join(' / ')}</>;

  }
  