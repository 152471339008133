import React,{useState,useEffect,useContext} from 'react'
import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';

import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import RoleOptionBar from './components/RoleOptionBar';
import StaffArchiveOptionBar from './components/StaffArchiveOptionBar';
import { useBanner } from '../../../../services/SystemBannerMessage';

export default function SuperAdminManageSchoolAdmin() {
  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const location = useLocation();
  const navigateTo = useNavigate();
  const userId = authState.user;
  const {schoolId} = useParams();
  const {showBanner} = useBanner();

  const [schoolInfo,setSchoolInfo] = useState();
  const [allTeachers,setAllTeachers] = useState([]);

  const [hideForm,setHideForm] = useState(true);
  const [adminExistAlert,setAdminExistAlert] = useState(true);
  const [emptyFieldAlert,setEmptyFieldAlert] = useState("");

  const handleShowForm = ()=>{
    setHideForm(!hideForm);
  }

  const [newAdminInfo, setNewAdminInfo] = useState({
    firstName:"",
    lastName:"",
    email:"",
    password:"",
    schoolId:schoolId
  })

  const handleNewAdminInfo = (e)=>{
    setAdminExistAlert(true)
    setNewAdminInfo({...newAdminInfo,[e.target.name]:e.target.value})
  }

  const getSchool = async()=>{
    await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}`,{withCredentials:true})
      .then((response)=>{
        if(response.status === 200){
          setSchoolInfo(response.data.payload)
        }
      }
      ).catch((err)=>{
        navigateTo(-1);
      });
  }

  const getAllTeachers = async()=>{
    await useAxiosWithInterceptor.get(`/api/super-admin/schools/${schoolId}/teachers`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        // sort by teacher first name
        const sortedTeachers = response.data.payload.sort((a,b)=>{
          if(a.firstName < b.firstName){
            return -1;
          }
          if(a.firstName > b.firstName){
            return 1;
          }
          return 0;
        });
        setAllTeachers(sortedTeachers);
      }
    }).catch((error)=>{
      console.log(error)
      navigateTo(-1);
    })
  }

  const createNewAdmin = async()=>{

    for (const [key, value] of Object.entries(newAdminInfo)) {
      if(value === ""){
        alert(`Please fill in ${key} field`);
        return;
      }
      if(key === "email"){
        if(!value.includes("@")){
          alert("Please enter a valid email address");
          return;
        }
      }
    }

    if(allTeachers.find((teacher)=>teacher.email === newAdminInfo.email)){
      setAdminExistAlert(false)
      return;
    }

    await useAxiosWithInterceptor.post(`/api/super-admin/schools/${schoolId}/createAdmin`,{newAdmin:newAdminInfo},{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        // setHideForm(true);
        setNewAdminInfo({
          firstName:"",
          lastName:"",
          email:"",
          password:"",
          schoolId:schoolId
        })
        showBanner("New Admin Created Successfully","bg-green-500");
        getAllTeachers();
      }
    }).catch((error)=>{
      console.log(error)
    })

  }

  useEffect(()=>{
    getAllTeachers();
    getSchool();
  },[])

  return (
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 rounded-2xl bg-white px-3 py-4 '>
          <p className='text-3xl font-bold py-1 px-2'>{schoolInfo?.schoolName}</p>
          <p className='text-2xl py-1 px-2'>Staff</p>
          <div className='mt-8 flow-root shadow rounded-2xl '>
            <p className={`transition ease delay-100 w-full px-3 py-2 bg-sky-600 hover:bg-sky-700  text-white font-semibold cursor-pointer ${hideForm?"rounded-xl":"rounded-t-xl"}`} onClick={handleShowForm}>Create New Admin <span className='text-red-400 mx-3' hidden={adminExistAlert}>Staff With This Email <span className='text-white'>{newAdminInfo.email}</span> Already Exist</span></p>
            <div className='transition ease-in-out delay-150 p-3' hidden={hideForm}>
                <form  >
                  <div className='py-4 flex flex-col ' >
                    <input type='text' name='firstName' onChange={handleNewAdminInfo} value = {newAdminInfo['firstName']} placeholder='First Name' className='sm:w-2/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                    <input type='text' name='lastName' onChange={handleNewAdminInfo} value={newAdminInfo['lastName']} placeholder='Last Name' className='sm:w-2/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                    <input type='email' name='email' onChange={handleNewAdminInfo} value={newAdminInfo['email']} placeholder='Email' className='sm:w-2/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                    <input type='password' name='password' onChange={handleNewAdminInfo} value={newAdminInfo['password']} placeholder='Password' className='sm:w-2/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                  </div>
                </form>
                <button onClick={createNewAdmin} className='transition ease-in-out delay-50 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 sm:w-2/4 w-full rounded my-2'>Create</button>
            </div>
          </div>
          <div className='mt-4 mx-3'>
            <p className='font-semibold'>Manage Roles</p>
          </div>

          <div className='mt-2 flow-root shadow-sm rounded-2xl overflow-x-auto'>
            <div className='inline-block min-w-full align-middle '>
              <table className='min-w-full border-separate border-spacing-0'>
                <thead className=''>
                  <tr className='text-center bg-green-500'>
                    <th className='sticky top-0 border-b border-gray-300 bg-white bg-opacity-75 py-2.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8 rounded-tl-2xl'>Name</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell'>Email</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell'>Role</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell'> Status</th>
                    <th className='sticky top-0  border-b border-gray-300 bg-white bg-opacity-75 px-3 py-2.5 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell rounded-tr-2xl'>Actions</th>
                    
                  </tr>
                </thead>
                <tbody>
                  {
                    allTeachers.map((teacher)=>{
                      return (
                        <tr key={teacher.id} className='shadow-sm text-center'>
                          <td className='whitespace-nowrap py-2 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{teacher?.firstName} {teacher.lastName}</td>
                          <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>{teacher?.email}</td>
                          <RoleOptionBar schoolId={schoolId} staffId={teacher.id} staffCurrentRole={teacher.assignments} getAllTeachers={getAllTeachers} />
                          <td className={`whitespace-nowrap py-2 px-3 text-sm font-bold text-gray-900 sm:pl-6 lg:pl-8 text-white`}>
                            <p className={`py-1 px-2 rounded-xl ${teacher?.archived?"bg-gray-500 ":"bg-green-600 "}`}>
                            {teacher?.archived?"Archived":"Active"}
                            </p>
                          </td>
                          <td className='whitespace-nowrap py-2 px-3 text-sm font-medium text-gray-900 sm:pl-6 lg:pl-8'>
                          <StaffArchiveOptionBar staffId={teacher?.id} refreshStaff={getAllTeachers} />
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
              </div>
            </div>
          </div>
      </div>
    </Layout>
  )
}
