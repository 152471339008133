import React from 'react'
import Layout from '../../../components/Layout';

export default function SuperAdminHome() {
  return (
    <>
        <Layout>
          <div className='w-full px-3'>
            <div>AdminHome</div>
          </div>
        </Layout>
    </>
  )
}
