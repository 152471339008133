import React, { useContext, useEffect, useState } from 'react'
import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { Link } from 'react-router-dom';
import { AuthenticationContext } from '../../../../services/AuthContextModule';

export default function SuperAdminContact() {
  return (
    <Layout>
    <div className='md:w-full w-full'>
      <div className='bg-opacity-75 rounded-2xl px-1 py-2 lg:mx-10'>
        <p>Super Administrator Contact page</p>
      </div>
    </div>
    </Layout>
  )
}
