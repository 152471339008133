import React from 'react'
import Layout from '../../../components/Layout'


export default function SuperAdminStudentProfile() {
  return (
    <Layout>
        <div>AdminStudentProfile</div>
    </Layout>
  )
}
