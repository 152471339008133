import {React,useState,useEffect,useContext} from 'react'
import Layout from '../../components/Layout';
import axiosWithInterceptor from '../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../services/AuthContextModule';
import { useNavigate, useLocation,Link } from 'react-router-dom';

import { SchoolNewEventFormContext, SchoolNewEventFormDispatchContext } from './providers/SchoolEventFormContext';
import SchoolEventInputBox from './components/SchoolEventInputBox';
import SchoolEventType from './components/SchoolEventType';
import SchoolEventCategory from './components/SchoolEventCategory';
import SchoolEventDatePicker from './components/SchoolEventDatePicker';
import SchoolEventEndDatePicker from './components/SchoolEventEndDatePicker';
import SchoolEventFormDeadlinePicker from './components/SchoolEventDeadlinePicker';
import SchoolEventGoogleToggle from './components/SchoolEventGoogleToggle';
import SchoolEventParentsToggle from './components/SchoolEventParentsToggle';
import SchoolEventOpenToAll from './components/SchoolEventOpenToAll';
import SchoolEventStudentSelector from './components/SchoolEventStudentSelector';
import { SchoolNewEventFormRequiredDispatchContext } from './providers/SchoolEventFormValidationContext';


export default function SchoolAllCreateEvent() {
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const navigate = useNavigate();
  const location = useLocation();

  const [emptyFields, setEmptyFields] = useState([]);

  const eventFormState = useContext(SchoolNewEventFormContext);
  const eventFormDispatch = useContext(SchoolNewEventFormDispatchContext);
  const validationDispatch = useContext(SchoolNewEventFormRequiredDispatchContext);

  const checkState =()=>{
    console.log(eventFormState)
  }

  const handleSubmit = async ()=>{
    for(const [key,value] of Object.entries(eventFormState)){
      validationDispatch({
        type:"submitCheckEmpty",
        currentField: key
      })
    }

    let formHasEmptyValues = true;
    let copyEmptyField = [...emptyFields];

    for(const [key,value] of Object.entries(eventFormState)){
      formHasEmptyValues = false;
      if(value === "" && (key !== "selectedUniversities" && key !== "selectedStudents")){
        formHasEmptyValues = true;

        if(!copyEmptyField.includes(key)){
          copyEmptyField.push(key)
        }
        setEmptyFields(copyEmptyField)
        break;
      } else {
        if(copyEmptyField.includes(key)){
          let reducedFields = copyEmptyField.filter((field)=>field !== key);
          setEmptyFields(reducedFields);
        }
        formHasEmptyValues = false;
      }
  }

  console.log("form has empty values: "+formHasEmptyValues)

  if(formHasEmptyValues){
    console.log(emptyFields)
    return;
  }
  if(!formHasEmptyValues){
    const form = new FormData();
    for(const [key,value] of Object.entries(eventFormState)){
        if(key ==="selectedUniversities" || key ==="selectedStudents"){
            let ids = value.map((item)=>{
                return item.id
            })
            form.append(key,ids)
        } else{
            form.append(key,value)
        }
    }
    
    await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/events/new`,form,{withCredentials:true})
      .then((response)=>{
          if(response.status === 201){
            const eventId = response.data.payload.id
              navigate(`/school/events/${eventId}`,{state:{from: location},replace:true})
          }
    })
    .catch((error)=>{
          console.log(error)
      })
    }
  }

  useEffect(()=>{
    document.title = "Create Event"
  },[])
  return (
    <Layout>
    <div className='md:w-full w-full '>
      <div className='bg-opacity-75 rounded-2xl px-1 lg:mx-5'>
        <div className='mb-3 py-3 flex justify-center bg-white'>
          <p className='text-lg font-semibold'>New Event</p>
        </div>

        <div className="h-fit lg:flex space-y-2 lg:space-y-0">
          <div className="space-y-2 border-b bg-white border-gray-900/10 pb-12 sm:space-y-0 sm:divide-y sm:divide-gray-900/10 sm:pb-0 shadow-sm rounded-2xl px-3 lg:w-3/5 lg:mr-3">
              <SchoolEventInputBox inputName={"Event Name"} inputType={"text"} attributeName={"eventName"} value={eventFormState?.eventName} />
              <SchoolEventInputBox inputName={"Remark"} inputType={"text"} attributeName={"remark"} value={eventFormState?.remark} rows={4}/>
              <SchoolEventType inputName={"Event Type"} attributeName={"eventType"} />
              <SchoolEventInputBox inputName={eventFormState?.eventType ==="in person"?"Location":"Zoom Link or Url"} inputType={"text"} attributeName={"location"} value={eventFormState?.location} />
              <SchoolEventCategory />
              {/* date picker  */}
              <SchoolEventDatePicker />
            {/* end date picker */}
              <SchoolEventEndDatePicker />
            {/* sign up deadline datepicker */}
              <SchoolEventFormDeadlinePicker />
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
                <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 "> Duration </label>
                {
                  eventFormState.eventEndTime !== "" && eventFormState.eventDateTime !== "" ? (
                    <p className='font-semibold text-teal-500 py-1'> {getDuration(eventFormState.eventDateTime, eventFormState.eventEndTime)} </p>
                    ) : (
                      <></>
                    )
                } 
              </div>
              {/* Google Calendar */}
              <SchoolEventGoogleToggle />
              {/* Invite Parents */}
              <SchoolEventParentsToggle />
              {/* Event Openness */}
              <SchoolEventOpenToAll />
          </div>

          <div className='rounded-2xl bg-white px-3 py-3 lg:w-2/5 shadow-sm'>
            <p className='font-semibold text-center'>Attendance</p>
            <div id='student-selector'>
                <SchoolEventStudentSelector 
                  attributeName={"selectedStudents"} 
                  inputName={"Invite Students"} />
            </div>
          </div>
        </div>
        <div className="lg:space-x-4 py-2 lg:flex justify-between ">
          <button 
          onClick={handleSubmit} 
          className='transition delay-50 w-full lg:w-64 font-semibold px-5 py-3 border bg-yellow-300 hover:bg-yellow-400 shadow-sm hover:shadow mb-2' 
          >CREATE EVENT</button>
          <Link to={`/school/events`} >
            <button 
            className='transition delay-50 w-full lg:w-64 font-semibold px-5 py-3  border bg-gray-300 hover:bg-gray-200 shadow-sm hover:shadow mb-2' 
            >CANCEL</button>
          </Link>
        </div>
        
        {/* <button className='w-64 font-semibold px-5 py-2 rounded-sm border hover:bg-green-500 shadow-sm' onClick={checkState}>Check State</button> */}
      </div>
    </div>
    </Layout>
  )
}



const getDuration = (eventStart, eventEnd) => {
  const start = new Date(eventStart);
  const end = new Date(eventEnd);

  if (isNaN(start) || isNaN(end)) {
    return "Invalid date(s)";
  }

  let totalMinutes = (end - start) / 1000 / 60;
  let hours = Math.floor(totalMinutes / 60);
  let minutes = Math.floor(totalMinutes % 60); // Use Math.floor to avoid rounding up to 60

  let display = "";
  if (hours > 0) {
    display += hours + (hours > 1 ? " hours " : " hour ");
  }
  if (minutes > 0 || display === "") { // Ensure we display "0 minutes" if there are no hours
    display += minutes + (minutes !== 1 ? " minutes" : " minute");
  }

  return display.trim();
};