import React, { useContext, useState, Fragment, useRef} from 'react'
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import {AuthenticationContext } from '../../../../services/AuthContextModule';
import { useLocation,useNavigate} from "react-router-dom";
import { TrashIcon, ArchiveBoxIcon } from '@heroicons/react/20/solid'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import { useBanner } from '../../../../services/SystemBannerMessage';

export default function UnArchiveStaffButton({staff}) {

    const [showModal, setShowModal] = useState(false);
    const [open, setOpen] = useState(false)
    const cancelButtonRef = useRef(null);
    const [buttonDisabled, setButtonDisabled] = useState(true);

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const schoolId = authState.schoolId;

    const navigateTo = useNavigate();
    const location = useLocation();
    const {showBanner} = useBanner();
    const [archiveConfirm, setArchiveConfirm] = useState('');


    const handleArchiveConfirm = (e)=>{
        let value = e.target.value;
        setArchiveConfirm(value);
        if(value === 'ARCHIVE'){
            setButtonDisabled(false);
        }else{
            setButtonDisabled(true);
        }
    }
    
    const handleShowModal = ()=>{
        setOpen(true)
    }

    const handleCloseModal = ()=>{
        setShowModal(false)
    }

    const handleUnArchive = async()=>{
        await useAxiosWithInterceptor.put(`/api/user/${userId}/school/${schoolId}/administrator/teachers/${staff.id}/unarchive`,{staffId:userId},{withCredentials:true})
        .then((response)=>{
            if(response.status === 200){
                navigateTo('/school/administrator/staff',{state:{from: location},replace:true})
            }
            showBanner("Staff UnArchived","bg-green-400")
        }) 
        .catch((error)=>{
            console.log(error)
        })
    }

  return (
    <>
        <button
            type='button'
            onClick={handleShowModal}
            className='inline-flex items-center gap-x-1.5 rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
            <ArchiveBoxIcon className="-ml-0.5 h-5 w-5" aria-hidden="true" />
            Un-ARCHIVE {staff?.firstName?.toUpperCase()} {staff?.lastName?.toUpperCase()}
        </button>
        <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
            <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>
            <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
              <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-3 pt-3 text-left shadow-xl transition-all sm:my-4 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="sm:flex sm:items-center">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        ARCHIVE account
                        </Dialog.Title>
                        <div className=" mt-2 text-left font-semibold text-sm">
                            <div className='mb-4'>
                              <p className="text text-red-500">You are about to Archive this Staff and all his/her records</p>
                            </div>
                            <div className='flex '>
                              <div className='w-3/4 grid gap-y-1 ml-5'>
                                <p>{staff?.firstName} {staff?.lastName}</p>
                                <p>{staff?.email}</p>
                                <p>{staff?.school?.schoolName}</p>
                              </div>
                              <div className='mx-auto '>
                                <div className="flex items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                                    <ExclamationTriangleIcon className="h-20 w-20 text-red-600" aria-hidden="true" />
                                </div>
                              </div>
                            </div>

                        </div>
                      `</div>
                    </div>
                    <div className="mt-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                            type="button"
                            className="mt-3 inline-flex w-full justify-center rounded-md bg-green-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                            onClick={handleUnArchive}>
                            UnArchive
                        </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => setOpen(false)}
                        ref={cancelButtonRef} >
                        Cancel
                      </button>
                    </div>
                </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
        </Dialog>
        </Transition.Root>
    </>
  )
}
