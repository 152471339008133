import React, { useEffect, useState,useContext } from 'react'
import Layout from '../../../components/Layout';

import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { Link, useParams } from 'react-router-dom';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import moment from 'moment';
import { HandRaisedIcon, ClockIcon, MapPinIcon, LockOpenIcon, LockClosedIcon,ClipboardDocumentIcon } from '@heroicons/react/24/outline';
import SeparatorLine from '../../../schoolGeneral/schoolComponents/SeparatorLine';

export default function SuperAdminEventDetails() {

    const useAxiosWithInterceptor = axiosWithInterceptor();
    const {authState} = useContext(AuthenticationContext);
    const userId = authState.user;
    const {eventId} = useParams();

    const [eventDetails, setEventDetails] = useState({});
    const [gradeLevels, setGradeLevels] = useState([]);

    const [inviteesTable, setInviteesTable] = useState([]);
    const [invitees, setInvitees] = useState([]);
    const [accepted, setAccepted] = useState([]);
    const [declined, setDeclined] = useState([]);
    const [pending, setPending] = useState([]);

    const [statusTabs, setStatusTabs] = useState([
        {active:true, tabName:"INVITED",content:invitees,bgColor:"bg-green-400"},
        {active:false, tabName:"ACCEPTED",content:accepted,bgColor:"bg-green-500"},
        {active:false, tabName:"DECLINED",content:declined,bgColor:"bg-red-500"},
        {active:false, tabName:"PENDING",content:pending,bgColor:"bg-amber-500"}
    ]);

    const handleSetContent = (index, statusContent)=>{
        // console.log(statusContent)
        const newActiveTabs = [...statusTabs];
        newActiveTabs[index].content = statusContent;
        setStatusTabs(newActiveTabs);
      }
  
      const handleInviteeTable = (statusData, index)=>{
        setInviteesTable(statusData);
        const newActiveTabs = [...statusTabs];
        newActiveTabs.forEach((tab)=>{
          tab.active = false;
        });
    
        newActiveTabs[index].active = true;
        setStatusTabs(newActiveTabs);
      }

      const handleAllInvitees = (statusData)=>{
        setInvitees(statusData);
        handleSetContent(0, statusData);
      }
  
      const handleAccepted = (statusData)=>{
        const accepted = statusData.filter((invitee)=>invitee.status==="accepted");
        setAccepted(accepted);
        handleSetContent(1, accepted);
        
      }
  
      const handleDeclined = (statusData)=>{
        const declined = statusData.filter((invitee)=>invitee.status==="declined");
        setDeclined(declined);
        handleSetContent(2, declined);
      }
  
      const handlePending = (statusData)=>{
        const pending = statusData.filter((invitee)=>invitee.status==="pending");
        setPending(pending);
        handleSetContent(3, pending);
      }

      const loadEventDetails = async()=>{
        await useAxiosWithInterceptor.get(`/api/super-admin/events/${eventId}`,{withCredentials:true})
        .then((response)=>{
            let eventDetails = response.data.payload;
            setEventDetails(eventDetails)
            let invitedStudents = eventDetails.invitations.map((invitation)=>{
              return invitation.student;
            });
            handleAllInvitees(eventDetails.invitations);
            
            handleAccepted(eventDetails.invitations); 
            handleDeclined(eventDetails.invitations);
            handlePending(eventDetails.invitations);
            handleInviteeTable(eventDetails.invitations, 0);
            getGradeLevels(eventDetails);

        }).catch((error)=>{
            console.log("error in loading event details",error)
        })
    }
    useEffect(()=>{
        loadEventDetails()
    },[])

  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const copyTableToClipBoard = ()=>{
    const table = document.getElementById('inviteesTable');
    const rows = Array.from(table.querySelectorAll('tr'));
    const text = rows.map(row => {
      const cells = Array.from(row.querySelectorAll('th, td'));
      return cells.map(cell => cell.textContent).join('\t');
    }).join('\n');

    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then(() => {
        setShowCopyMessage(true);
        setTimeout(() => setShowCopyMessage(false), 1000);
      }).catch(err => {
        console.error('Failed to copy: ', err);
      });
    } else {
      console.warn('Clipboard API not supported');
    }
  }

  const getGradeLevels = (eventObject)=>{
    let students = eventObject.invitations.map((invitation)=>{
        return invitation.student
        }
    )
    let gradeLevels = students.map((student)=>{
        return student.gradeLevel
    })
    gradeLevels = gradeLevels.sort((a,b)=>a-b);
    gradeLevels = [...new Set(gradeLevels)];
    setGradeLevels(gradeLevels);
  }

  useEffect(()=>{
    document.title ="Event Details";
  },[])

  return (
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 lg:grid grid-cols-10 gap-x-2 lg:mx-10'>
          <div className='col-span-6 lg:min-h-fit rounded-tl-2xl lg:mr-3'>
            <div id='event-info-section' className='mb-2 bg-white rounded-tl-2xl shadow-sm'>
              <div className='font-semibold px-3 py-2 bg-indigo-500 lg:rounded-tl-2xl flex justify-between'>
                <p className='text-lg text-white'>{eventDetails.eventName}</p>
                <p className='font-semibold text-sm rounded w-fit px-4 bg-white flex items-center justify-center'>{eventDetails.eventCategory?.categoryName.toUpperCase()}</p>
              </div>
              <div className='px-3 py-2'>     

                <div className='grid grid-cols-2 items-center text-sm'>
                  <div className='flex items-center '>
                    <ClockIcon className='h-7 w-7 text-sky-500'/>
                    <p className='font-semibold mx-1 '>Time: </p>
                  </div>
                  <p className='font-semibold my-2 w-fit rounded-lg text-sky-600 px-2 '>{moment(eventDetails.eventDateTime).calendar(null,momentOptions)} </p>
                </div>
                
                <SeparatorLine margin={1} />
                <div className='grid grid-cols-2 items-center text-sm'>
                  <div className='flex items-center '>
                    <ClockIcon className='h-7 w-7 text-amber-400 font-semibold' />
                    <p className='font-semibold mx-1 '>End at: </p>
                  </div>

                  <p className='font-semibold my-2 w-fit rounded-lg text-sky-600 px-2 '>{moment(eventDetails.eventEndTime).calendar(null,momentOptions)}</p>
                </div>
                <SeparatorLine margin={1} />
                  <div className='grid grid-cols-2 items-center text-sm'>
                    <div className='flex items-center '>
                      <HandRaisedIcon className='h-7 w-7 text-red-500' />
                      <p className='font-semibold mx-1 '>Sign up Deadline: </p>
                    </div>
                    <p className='font-semibold my-2 w-fit rounded-lg px-2 text-sky-600 '> {moment(eventDetails.signUpDeadline).calendar(null,momentOptions)}</p>
                  </div>
                <SeparatorLine margin={1} />
                  <div className='grid grid-cols-2 items-center text-sm justify-between'>
                    <div className='flex items-center '>
                      <MapPinIcon className='h-7 w-7 text-blue-500 ' />
                      <p className='font-semibold mx-1 '>Location: </p>
                    </div>
                    <p className='font-semibold my-2 w-fit border-2 border-green-500 rounded-lg text-green-500 px-2 mx-2'>{eventDetails.location}</p>
                  </div>

                <SeparatorLine margin={1} />

                <div className='grid grid-cols-2 items-center text-sm'>
                    <div className='flex items-center '>
                  { eventDetails.openToAll?
                      <LockOpenIcon className='h-7 w-7 text-blue-500' /> :
                      <LockClosedIcon className='h-7 w-7 text-red-500' /> }
                  {/* <LockOpenIcon className='h-7 w-7 text-blue-500' /> */}
                  <p className='font-semibold mx-2 '>Event Type: </p>
                  </div>
                  <p className='font-semibold my-2 w-fit rounded-lg text-sky-600 px-2 '>{eventDetails.openToAll?"OPEN TO ALL":"INVITE ONLY"}</p>
                 
                </div>

                  <SeparatorLine margin={1} />

                  <div className='lg:grid grid-cols-2 items-center text-sm justify-between my-2 '>
                    <p className='font-semibold text-xs'>Organized By: {eventDetails.staff?.firstName} {eventDetails.staff?.lastName}</p>
                  </div>
                  <SeparatorLine margin={1} />
                  <div className='lg:grid grid-cols-2 items-center text-sm justify-between my-2 '>
                    <p className='font-semibold text-blue-500'>{eventDetails.school?.schoolName}</p>
                  </div>

              </div>
            </div>

            <div className='lg:min-h-fit h-24 shadow-sm bg-white lg:my-0 lg:my-4'>
              <p className='px-3 py-2 font-semibold'>Grades Included</p>
              <div className='px-3 flex space-x-3'>
                {
                  gradeLevels.map((grade,index)=>{
                      return <p key={index} className='font-semibold text-base text-center flex items-center justify-center bg-yellow-300 w-10 h-10 rounded-full text-center shadow-sm border'>{grade}</p>
                  })
                }
              </div>
            </div>

            <div className='lg:min-h-fit h-48 shadow-sm bg-white lg:my-0 lg:my-4'>
              <p className='px-3 py-2 bg-sky-700 text-white font-semibold'>Details</p>
              <p className='px-3 py-2 text-sm font-semibold'>{eventDetails.remark}</p>
            </div>

            <div className='flex gap-x-2 mt-2 bg-white px-3 py-3 lg:rounded-bl-2xl '>
              {
                eventDetails.staff?.id === userId?
                moment(eventDetails.eventDateTime).isAfter(moment())?
                <>
                  <Link to={`/school/events/${eventId}/edit`} >
                    <button className='transition delay-100 bg-green-500 text-white font-semibold rounded-lg px-4 py-1 hover:bg-green-600'>Edit Event</button>
                  </Link>
                 <button className='transition delay-100 bg-red-500 text-white font-semibold rounded-lg px-4 py-1 hover:bg-red-600'>Delete Event</button>
                </>
                 :<button className='bg-red-500 text-white font-semibold rounded-lg px-3 py-1'>Delete Event</button>
                :<p className='text-sm font-semibold'>Event Created by: {eventDetails.staff?.firstName} {eventDetails.staff?.lastName}</p>
              }
            </div>
          </div>
          
          {/* Attendance column */}
          <div id='attendance-section' className='col-span-4 bg-white lg:rounded-r-2xl ml-3 relative'>
            <div className='flex justify-between bg-indigo-500 text-white lg:rounded-tr-2xl'>
              <p className='px-3 py-2 font-semibold'>Attendance</p>
              <button className='mx-2 p-1 rounded relative' onClick={copyTableToClipBoard}>
                <ClipboardDocumentIcon className='h-6 w-6 hover:text-slate-300'/>
              </button>
              {showCopyMessage && (
                <div className="absolute top-0 right-0 bg-red-500 font-semibold px-2 py-1 rounded-tr-2xl text-base">
                  Copied!
                </div>
              )}
            </div>
           {/* overall count / status tabs */}
            <div className='py-0 shadow-sm mb-2 grid grid-cols-4 bg-white'>
                {
                  statusTabs.map((status, index)=>{
                    return (
                      <button 
                        key={index} 
                        onClick={()=>handleInviteeTable(status.content, index)} 
                        className={`transition delay-100 text-center py-2 ${status.active?status.bgColor:""}`}>
                        <p className='text-3xl '>{status.content.length}</p>
                        <p className={`text-sm font-semibold text-slate-400 ${status.active?"text-white":""} `}>{status.tabName}</p>
                      </button>
                    )
                  })
                }
            </div>
              {/* invitation table */}
            <div className='py-0 shadow-sm mb-2 bg-white max-h-[560px] overflow-auto relative'>
              <table id='inviteesTable' className='min-w-full divide-y divide-gray-300'>
                <thead>
                  <tr className='sticky top-0 grid grid-cols-3 text-center'>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0 col-span-1">Name</th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0">Grade</th>
                    <th scope="col" className="text-sm font-semibold text-gray-900 sm:pl-0">Status</th>
                  </tr>
                </thead>
                <tbody className='divide-y divide-gray-300'>
                  {
                    inviteesTable.map((invitation)=>{
                      return (
                        <tr key={invitation.id} className='hover:bg-gray-100 text-center grid grid-cols-3'>
                          <td className='whitespace-nowrap text-sm text-blue-600 font-semibold py-3'>
                            {invitation.student.firstName} {invitation.student.lastName}
                          </td>
                          <td className='whitespace-nowrap px-3 py-3 text-sm'>{invitation.student.gradeLevel}</td>
                          <td className='whitespace-nowrap px-3 py-3 text-sm'>
                            <p className={`mx-3 rounded px-3 ${invitation.status ==="accepted"?"bg-green-500 text-white ":invitation.status==="declined"?"bg-red-500 text-white":"border-1 border-amber-500 text-amber-500"}`}>{invitation.status.toUpperCase()}</p>
                          </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </table>
            </div>
          </div>
          {/* <div className='col-span-1' /> */}
        </div>
      </div>
    </Layout>
  )
}


const momentOptions = {
    sameDay: '[Today] (dddd) [at] LT', // Today (Monday) at 2:30 PM
    nextDay: '[Tomorrow] (dddd) [at] LT', // Tomorrow (Tuesday) at 2:30 PM
    nextWeek: 'dddd, MM/DD/YYYY [at] LT', // Sunday, 09/20/2020 at 2:30 PM
    lastDay: '[Yesterday] (dddd) [at] LT', // Yesterday (Sunday) at 2:30 PM
    lastWeek: '[Last] dddd, MM/DD/YYYY [at] LT', // Last Monday, 09/14/2020 at 2:30 PM
    sameElse: 'MM/DD/YYYY (dddd) [at] LT' // 09/14/2020 (Friday) at 2:30 PM
  };