import React, { useEffect,useContext } from 'react';
import axiosWithInterceptor from '../../../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../../../services/AuthContextModule';

export default function EventParentInviter() {

  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {authState} = useContext(AuthenticationContext);
  const userId = authState.user;
  const schoolId = authState.schoolId;

  const testGetParents = async()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/counselor/students/parents`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        console.log(response.data.payload)
      }
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    testGetParents()
  },[])

  return (
    <div>
      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:py-2">
        <label className="block text-sm text-gray-900 font-medium px-2 py-1.5 ">
          Parents
        </label>
        <form className='my-1 col-span-2'>
          <div className='flex flex-wrap text-xs font-semibold '>

            
          </div>
        </form>
      </div>
    </div>
  )
}
