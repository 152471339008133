import React ,{ useContext, useEffect, useState }from 'react';

import Layout from '../../../components/Layout';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import { Link,useNavigate,useParams } from 'react-router-dom';
import SchoolRoleOptionBar from '../administratorComponents/SchoolRoleOptionBar';
import CreateStaffRoleOption from '../../../admin/pages/subPages/components/CreateStaffRoleOption';
import { useBanner } from '../../../../services/SystemBannerMessage';
import { delayLoading } from '../../../../utils/loadingDelay';
import SkeletonTableRow from '../../../pageComponents/SkeletonTableRow';

export default function AdministratorStaff() {

  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const userId = authState.user;
  const schoolId = authState.schoolId;
  const {showBanner} = useBanner();

  const [teachers, setTeachers] = useState([]);
  const [teacherTable, setTeacherTable] = useState([]);

  const [isPageLoading,setIsPageLoading] = useState(true);

  const [hideForm,setHideForm] = useState(true);
  const [adminExistAlert,setAdminExistAlert] = useState(true);

  const handleShowForm = ()=>{
    setHideForm(!hideForm);
  }

  const [newStaffInfo, setNewStaffInfo] = useState({
    firstName:"",
    lastName:"",
    email:"",
    password:"",
    school_id:schoolId,
    role:[]
  })

  const handleNewAdminInfo = (e)=>{
    e.preventDefault();
    setAdminExistAlert(true)
    setNewStaffInfo({...newStaffInfo,[e.target.name]:e.target.value})
  }

  const handleRoleInput = (role)=>{
    const roleCopy = [...newStaffInfo.role];
    if(roleCopy.includes(role)){  // if the role is already in the array
      const roleIndex = roleCopy.indexOf(role); // get the index of the role
      roleCopy.splice(roleIndex,1); // remove the role from the array
    } else {
      roleCopy.push(role);
    }
    setNewStaffInfo({...newStaffInfo,role:roleCopy});
  }

  const createNewStaff = async(e)=>{
      e.preventDefault();
      for (const [key, value] of Object.entries(newStaffInfo)) {
        if(value === ""){
          alert(`Please fill in ${key} field`);
          return;
        }
        if(key === "email"){
          if(!value.includes("@")){
            alert("Please enter a valid email address");
            return;
          }
        }
      }

      if(teachers.find((teacher)=>teacher.email === newStaffInfo.email)){
        setAdminExistAlert(false)
        return;
        // alert(`Staff with email ${newAdminInfo.email} already exist`);
      }

      await useAxiosWithInterceptor.post(`/api/user/${userId}/school/${schoolId}/administrator/teachers/new-teacher`,{newStaffInfo},{withCredentials:true})
      .then((response)=>{
        if(response.status === 201){
          setHideForm(true);
          setNewStaffInfo({
            firstName:"",
            lastName:"",
            email:"",
            password:"",
            school_id:schoolId,
            role:[]
          });
          showBanner("New Staff Created","bg-green-500")
          getAllTeachers();
        }
      }).catch((error)=>{
        console.log(error)
      })
  }

  const [searchValue, setSearchValue] = useState("");

  const handleSearch= (e)=>{
    setSearchValue(e.target.value);
    let filteredList = teachers.filter((teacher)=>{
      const searchKey = ["firstName","lastName","email"];
      return searchKey.some((key)=>{
        if(typeof teacher[key] === "string" && teacher[key].toLowerCase().includes(e.target.value.toLowerCase())){
          return teacher
        }
      });
    });
    setTeacherTable(filteredList);
  }

  const getAllTeachers = async()=>{
    await useAxiosWithInterceptor.get(`/api/user/${userId}/school/${schoolId}/administrator/teachers`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        setTeachers(response.data.payload)
        setTeacherTable(response.data.payload)
      }
    })
    .catch((e)=>{
      console.log(e);
    })
    .finally(()=>{
      setIsPageLoading(false);
    });
  }

  useEffect(()=>{
    document.title = "Staff"
    // delayLoading(getAllTeachers,2000)
    getAllTeachers();
  }
  ,[]);

  return (
    <Layout>
      <div className='md:w-full w-full'>
        <div className='bg-opacity-75 lg:mx-10'>
        <div className='font-semibold p-2 '>
          <p className='text-2xl font-bold'>School Staff</p>
        </div>
          <div className=' flow-root shadow rounded-2xl '>
            <p className={`transition ease delay-100 w-full px-3 py-2 bg-indigo-600 hover:bg-indigo-700 text-white font-semibold cursor-pointer ${hideForm?"rounded-xl":"rounded-t-xl"}`} onClick={handleShowForm}>Create New Account <span className='text-red-500 mx-3 bg-white rounded px-3 py-1' hidden={adminExistAlert}>Staff With This Email {newStaffInfo.email} Already Exist</span></p>
            <div className='transition ease-in-out delay-150 p-3' hidden={hideForm}>
                <form >
                  <div className='py-4 grid grid-cols-2 '>
                    <div className='col-span-1 flex flex-col w-full '>
                      <input type='text' name='firstName' onChange={handleNewAdminInfo} value = {newStaffInfo['firstName']} placeholder='First Name' className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                      <input 
                        type='text' 
                        name='lastName' 
                        onChange={handleNewAdminInfo} 
                        value={newStaffInfo['lastName']} 
                        placeholder='Last Name' 
                        autoComplete="username"
                        className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                      <input 
                        type='email' 
                        name='email' 
                        onChange={handleNewAdminInfo} 
                        value={newStaffInfo['email']} 
                        autoComplete="email"
                        placeholder='Email' className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                      <input 
                        type='password' 
                        name='password' 
                        onChange={handleNewAdminInfo} 
                        value={newStaffInfo['password']} placeholder='Password' 
                        autoComplete="new-password"
                        className='sm:w-3/4 w-full my-1 py-2 border border-gray-300 rounded w-1/2 py-1 px-2' />
                      <CreateStaffRoleOption getSelectedRoles={handleRoleInput} />
                      <button onClick={createNewStaff} className='transition ease-in-out delay-50 bg-indigo-500 hover:bg-blue-700 text-white font-bold py-2 sm:w-3/4 w-full rounded my-2'>Create</button>
                    </div>
                    <div className='col-span-1 px-3 space-y-2 font-semibold'>
                      <p className='text-xl '>New Staff Information</p>
                      <p >First Name: <span className='mx-5 text-lg text-indigo-600'>{newStaffInfo.firstName}</span></p>
                      <p>Last Name: <span className='mx-5 text-lg text-indigo-600'>{newStaffInfo.lastName}</span></p>
                      <p>Email: <span className='mx-5 text-lg text-indigo-600'>{newStaffInfo.email}</span></p>
                      {/* <p>Password: <span>{newStaffInfo.password}</span></p> */}
                      {/* <p>Role: <span className='mx-5 text-lg text-indigo-600'>{newStaffInfo.role}</span></p> */}
                    </div>
                  </div>
                </form>
            </div>
          </div>
          <div className='px-0 sm:px-6 lg:px-8 sm:flex sm:items-center gap-x-2 mt-3'>
            <div className='flex items-center w-full lg:w-4/5 md:w-4/5 sm:w-4/5 py-2 '>
              <p className='py-2 px-5 font-semibold text-sm bg-amber-400 rounded-l-2xl shadow-sm text-gray-700'>Search Teacher</p>
              <input 
                value={searchValue}
                onChange={handleSearch}
                placeholder='find teacher' 
                className='shadow-sm block flex-1 border-0 bg-slate-150 py-1.5 pl-2 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 rounded-r-2xl'/>
            </div>
          </div>
          <div className=" mx-0">
              <div className=" shadow-sm ring-1 ring-black ring-opacity-5 sm:rounded-2xl overflow-auto max-h-160 relative">
                <table className="min-w-full divide-y divide-gray-200 bg-white">
                  <thead className="bg-slate-200 text-left">
                    <tr>
                      <th scope="col" className="sticky top-0 px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-1/6">
                          Name
                      </th>
                      <th scope="col" className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-2/6">
                          Email
                      </th>
                      <th scope="col" className="px-3 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider w-3/6">
                          Role
                      </th>
                    </tr>
                  </thead>
                  <tbody className='divide-y divide-gray-300'>
                    { 
                      isPageLoading?
                      <SkeletonTableRow colNumber={3} rowNumber={11} />
                      :   // after page loading
                      teacherTable.map((teacher,index)=>{
                        return (
                          <tr key={teacher.id} className='hover:bg-gray-100 text-left'>
                            <td className='whitespace-nowrap py-2 px-3 text-sm text-blue-600 font-semibold w-56'>
                              <Link to={`/school/administrator/staff/${teacher.id}`}>
                              {teacher.firstName} {teacher.lastName}
                              </Link>
                            </td>
                            <td className='whitespace-nowrap py-2 px-3 text-sm w-1/6 break-words'>
                                {teacher.email}
                            </td>
                            <td className='whitespace-nowrap py-2 text-sm font-medium text-gray-900 w-3/6 '>
                              <SchoolRoleOptionBar schoolId={schoolId} staffId={teacher.id} staffCurrentRole={teacher.assignments} getAllTeachers={getAllTeachers} />
                            </td>
                            
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              </div>
          </div>
      </div>
      </div>

    </Layout>
  )
}
