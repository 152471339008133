import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout'
import { Link, useLocation,useNavigate,useParams } from "react-router-dom";
import {UserGroupIcon,XMarkIcon as XMarkIconMini} from '@heroicons/react/20/solid';
import { displayRoles } from '../../../../utils/displayRoles';
import axiosWithInterceptor from '../../../../hooks/axiosInterceptor';
import { AuthenticationContext } from '../../../../services/AuthContextModule';
import RenewSubscriptionYear from '../../adminComponents/SuperAdmin_RenewSubscriptionYear';
import DeActivateSubscription from '../../adminComponents/SuperAdmin_DeActivateSubscription';

import moment from 'moment';
import ActivateSubscription from '../../adminComponents/SuperAdmin_ActivateSubscription';

export default function SuperAdminManageSubscription() {
  const {authState} = useContext(AuthenticationContext);
  const useAxiosWithInterceptor = axiosWithInterceptor();
  const {schoolId} = useParams()

  const [activeSubscription,setActiveSubscription] = useState([]);
  const [previousSubscriptions, setPreviousSubscriptions] = useState([]);

  const handleActiveSubscriptions = (subscriptions)=>{
    let active = subscriptions.filter((sub)=>{
      return sub.status === "active"
    })
    setActiveSubscription(active)
  }

  const handleAllSubscriptions = (subscriptions)=>{
    let previous = subscriptions.filter((sub)=>{
      return sub.status ==="expired" || sub.status ==="inactive"
    })
    setPreviousSubscriptions(previous)
  }

  const getSubscriptions = async ()=>{
    await useAxiosWithInterceptor.get(`api/super-admin/schools/${schoolId}/subscriptions`,{withCredentials:true})
    .then((response)=>{
      if(response.status === 200){
        handleActiveSubscriptions(response.data.payload);
        handleAllSubscriptions(response.data.payload);
      };
    })
    .catch((error)=>{
      console.log(error)
    })
  }

  useEffect(()=>{
    getSubscriptions();
  },[])

  return (
    <Layout>
     <div className='w-full h-full'>
        <div className='bg-opacity-75 px-1 py-2 lg:mx-10'>
          <div className='mb-3'>
            <p className='text-2xl font-bold py-1 my-2'> Current Subscription</p>
            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white'>
              <table className="w-full whitespace-nowrap text-sm leading-6 ">
                <thead className="border-b border-gray-200 text-gray-900">
                  <tr className='text-center bg-slate-200'>
                    <th className="py-2 font-semibold">Type </th>
                    <th className="py-2 font-semibold">Starting Date</th>
                    <th className="hidden py-2 font-semibold sm:table-cell">Ending Date</th>
                    <th className=" py-2 font-semibold sm:table-cell">Price</th>
                    <th className=" py-2 font-semibold sm:table-cell">Status</th>
                    <th className=" py-2 font-semibold sm:table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    activeSubscription.map((sub)=>(
                      <tr key={sub.id} className='text-blue-500'>
                        <td className="pl-3 py-2 align-top font-bold  text-center">
                          {sub?.subscriptionType}
                        </td>
                        <td className="pl-3 py-2 align-top font-bold  text-center">
                          {moment(sub?.startingDate).format("MM-DD-YYYY")}
                        </td>
                        <td className=" pl-3 py-2 align-top font-bold  text-center">
                        {moment(sub?.endingDate).format("MM-DD-YYYY")}
                        </td>
                        <td className=" pl-3 py-2 align-top font-bold text-green-600 text-center">
                        $ {sub?.price}
                        </td>
                        <td className=" pl-3 py-2 align-top font-bold text-green-600 text-center">
                        {sub?.status}
                        </td>
                        <td className=" pl-3 py-2 align-top font-bold text-green-600 text-center">
                          <DeActivateSubscription schoolId={schoolId} subscriptionId={sub?.id} refreshSubscriptions={getSubscriptions} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>

          <div className='mb-3'>
            <p className='text-2xl font-bold py-1 my-2'>Subscription History</p>
            <div className='shadow-md ring-1 ring-gray-900/5 rounded-lg bg-white'>
              <table className="w-full whitespace-nowrap text-sm leading-6 ">
                <thead className="border-b border-gray-200 text-gray-900">
                  <tr className='text-center bg-gray-200'>
                    <th className="py-2 font-semibold">Type </th>
                    <th className="py-2 font-semibold">Starting Date</th>
                    <th className=" py-2 font-semibold sm:table-cell">Ending Date</th>
                    <th className=" py-2 font-semibold sm:table-cell">Price</th>
                    <th className=" py-2 font-semibold sm:table-cell">Status</th>
                    <th className=" py-2 font-semibold sm:table-cell">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    previousSubscriptions.map((sub)=>(
                      <tr key={sub.id} className='text-center '>
                        <td className="pl-3 py-2 align-top font-medium text-gray-900 ">
                          {sub?.subscriptionType}
                        </td>
                        <td className="pl-3 py-2 align-top font-medium text-gray-900 ">
                          {moment(sub?.startingDate).calendar()}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 ">
                        {moment(sub?.endingDate).calendar()}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 font-semibold">
                        $ {sub?.price}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 font-semibold">
                        {sub?.status}
                        </td>
                        <td className=" pl-3 py-2 align-top font-medium text-gray-900 font-semibold">
                          <ActivateSubscription schoolId={schoolId} subscriptionId={sub?.id} refreshSubscriptions={getSubscriptions} />
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </div>
          </div>
          <div className='my-3 grid lg:grid-cols-2'>
            <div className='my-2'>
              {/* <button className='rounded-lg px-3 py-1 bg-green-500 shadow-sm text-white font-semibold'>Renew Annual Subscription</button> */}
              <RenewSubscriptionYear schoolId={schoolId} getAllSubscriptions={getSubscriptions} />
            </div>
            <div className='my-2'>
              <button className='rounded-lg px-4 py-2 bg-blue-500 shadow-sm text-white font-semibold'>Extend by One Month</button>
            </div>
          </div>
        </div>
    </div>
    </Layout>
  )
}
